@import "fonts";

$transition: all .3s linear 0s;

body {
    margin: 0;
    padding: 0;
}
section {
    height: 100vh;
    &.one {
        background: palevioletred;
    }&.two {
        background: papayawhip;

    }&.three {
        background: powderblue;
    }
}


.chat-wrapper {
    position: fixed;
    bottom: 10px;
    right: 20px;
    * {
        box-sizing: border-box;
    }
    .chat-box {
        display: none;
        // display: flex;
        flex-direction: column;
        width: 270px;
        height: 360px;
        position: fixed;
        bottom: 10px;
        right: 80px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px 4px 0px 0px;
        &.active {
            display: flex;
        }
        .chat-top {
            background-color: #FFCD00;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            scroll-margin-bottom: 40px;
            padding: 0 10px;
            &-title {
                margin: 0;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #222222;
            }

            .left {
                display: flex;
                align-items: center;
            }
            .prev {
                margin-right: 10px;
                &.hide {
                    display: none;
                }
            }

            .btn-items {
                display: flex;
                &>* {
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .chat-settings {
                padding: 0 5px;
            }
            .chat-close {
                padding: 0 5px;
                
            }
        }

        .chat-subtop {
            display: none;
            width: 100%;

            &.show-chat-subtop {
                display: block;
            }

            .chat-subtop-item {
                display: flex;
                align-items: center;
                height: 35px;
                width: 100%;
                padding: 0 10px;
                border-bottom: 1px solid #c5c5c5;
                cursor: pointer;
                user-select: none;

                .chat-subtop-item-title {
                    margin: 0;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #303032;
                }
                svg {
                    margin-left: 10px;
                }
                input {
                    width: 100%;
                    border: none;
                    height: 33px;
                    margin-left: 10px;
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        .chat-content {
            max-height: 320px;
            min-height: 260px;
            width: 100%;
            overflow-y: auto;
            position: relative;
            &::-webkit-scrollbar {
                width: 5px;
            }
             
            &::-webkit-scrollbar-track {
                background: #fff;
            }
             
            &::-webkit-scrollbar-thumb {
                background: #C5C5C5;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }


            .chat-message-items {

                .action-box {
                    display: none;
                } 

                .chat-message-item {
                    display: flex;
                    align-items: center;
                    height: 55px;
                    padding: 10px 20px 10px 10px;
                    position: relative;
                    border-bottom: 1px solid #C5C5C5;
                    user-select: none;

                    .chat-message-item-icon {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 15px;
                        .indicator-online {
                            width: 7px;
                            display: none;
                            height: 7px;
                            background-color: #00CA14;
                            border: 1px solid #fff;
                            border-radius: 100%;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            &.active {
                                display: block;
                            }
                        }
                    }

                    .chat-message-item-info {
                        display: flex;
                        flex-direction: column;
                        p {
                            margin: 0;
                            font-size: 12px;
                            font-family: 'Montserrat';
                            line-height: 15px;
                            color: #222222;
                            font-style: normal;
                            &.chat-message-item-name {
                                font-weight: 500;
                                letter-spacing: 0.02em;
                                color: #222222;
                                margin-bottom: 5px;
                            }
                            // &.chat-message-item-desc {
                            // }
                        }
                    }

                    .chat-message-item-count {
                        display: none;
                        position: absolute;
                        right: 10px;
                        align-items: center;
                        justify-content: center;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 10px;
                        color: #FFFFFF;
                        width: 15px;
                        height: 15px;
                        background-color: #222222;
                        border-radius: 50%;
                        &.active {
                            display: flex;
                        }
                    }
                }
            }
        }
        .chat-bottom {
            display: none;
        }

        .add-chat {
            cursor: pointer;
            position: absolute;
            bottom: 12px;
            right: 12px;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #222222;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        }
    }

    .chat-write-message {
        display: none;
        // display: flex;
        flex-direction: column;
        width: 270px;
        // height: 360px;
        position: fixed;
        bottom: 10px;
        right: 80px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px 4px 0px 0px;
        &.active {
            display: flex;
        }
        .chat-top {
            background-color: #fff;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            scroll-margin-bottom: 40px;
            padding: 0 10px;
            .left {
                display: flex;
                align-items: center;
            }
            .prev {
                margin-right: 10px;
                &.hide {
                    display: none;
                }
            }
            .single-people {
                display: flex;
                align-items: center;
                .img {
                    width: 22px;
                    min-width: 22px;
                    height: 22px;
                    margin-right: 10px;
                    // overflow: hidden;
                    position: relative;
                    &::after {
                        content: '';
                        display: none;
                        right: -1px;
                        bottom: -1px;
                        position: absolute;
                        border-radius: 50%;
                        width: 5px;
                        height: 5px;
                        border: 1px solid #fff;
                        background-color: #00CA14;
                    }
                    &.online {
                        &::after {
                            display: block;
                        }
                    }
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 100%;
                    }
                }
            }
            &-title {
                margin: 0;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #222222;
            }
            .btn-items {
                display: flex;
                &>* {
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .chat-settings {
                padding: 0 5px;
            }
            .chat-close {
                padding: 0 5px;
                
            }
        }
        .chat-content {
            position: relative;
            height: 320px;
            display: flex;
            align-items: flex-end;
            .bg {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                .img {
                    width: 56px;
                    min-width: 56px;
                    height: 56px;
                    // overflow: hidden;
                    position: relative;
                    &::after {
                        content: '';
                        display: none;
                        right: 1px;
                        bottom: 1px;
                        position: absolute;
                        border-radius: 50%;
                        width: 10px;
                        height: 10px;
                        border: 1px solid #fff;
                        background-color: #00CA14;
                    }
                    &.online {
                        &::after {
                            display: block;
                        }
                    }
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 100%;
                    }
                }
            }
            .chat-message-items {
                position: relative;
                z-index: 2;
                padding: 0 10px 20px;
                // display: flex;
                // flex-direction: column;
                // justify-content: flex-end;
                // height: 100%;
                max-height: 320px;
                width: 100%;
                overflow-y: auto;
                position: relative;
                &::-webkit-scrollbar {
                    width: 5px;
                }
                
                &::-webkit-scrollbar-track {
                    background: #fff;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: #C5C5C5;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
                .chat-message-blocks {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    margin-bottom: 6px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &.ta-left {
                        justify-content: flex-end;
                        flex-direction: row-reverse;
                        .message-items {
                            align-items: flex-start;
                            .message-item {
                                background-color: #e9e9e9;
                            }
                        }
                        .people {
                            margin-right: 10px;
                        }
                    }
                    &.ta-right {
                        flex-direction: row;
                        .message-items {
                            align-items: flex-end;
                            .message-item {
                                background-color: #c9c9c9;
                            }
                        }
                        .people {
                            margin-left: 10px;
                        }
                    }
                    .message-items {
                        display: flex;
                        flex-direction: column;
                        .message-item {
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            padding: 6px 10px;
                            margin-bottom: 4px;
                            justify-content: flex-end;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            p {
                                margin: 0;
                                font-family: 'Montserrat';
                                font-style: normal;
                                font-weight: normal;
                                &.text {
                                    font-size: 12px;
                                    line-height: 15px;
                                    color: #222222;
                                    margin-right: 5px;
                                }
                                &.date {
                                    font-size: 8px;
                                    line-height: 10px;
                                    color: #565656;
                                }
                            }
                        }
                    }
                    .people {
                        width: 25px;
                        min-width: 25px;
                        height: 25px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }
            }
        }
        .chat-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px 20px;
            .file-attachment {
                input {
                    display: none;
                }
            }

            input.input-message {
                width: 100%;
                margin: 0 13px 0 10px;
                background-color: #fff;
                border: 1px solid #C5C5C5;
                border-radius: 15px;
                height: 30px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #222222;
                text-indent: 7px;
                &:focus {
                    outline: none;
                }
            }
            
            button {
                padding: 0;
                margin: 0;
                border: none;
                background-color: transparent;
            }
        }
    }

    .chat-group {
        display: none;
        // display: flex;
        flex-direction: column;
        width: 270px;
        height: 360px;
        position: fixed;
        bottom: 10px;
        right: 80px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px 4px 0px 0px;

        .chat-top {
            background-color: #FFCD00;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            min-height: 40px;
            scroll-margin-bottom: 40px;
            padding: 0 10px;
            &-title {
                margin: 0;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #222222;
            }

            .left {
                display: flex;
                align-items: center;
            }
            .prev {
                margin-right: 10px;
                &.hide {
                    display: none;
                }
            }

            .btn-items {
                display: flex;
                &>* {
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .chat-settings {
                padding: 0 5px;
            }
            .chat-close {
                padding: 0 5px;
                
            }
        }

        .group-info {
            height: 80px;
            min-height: 80px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            .left {
                margin-left: 20px;
                margin-right: 20px;
                width: 50px;
                min-width: 50px;
                max-width: 50px;
                height: 50px;
                position: relative;

                input {
                    display: none;
                }
                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: #303032;
                }
                img {
                    position: absolute;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    top: 0;
                    left: 0;
                    display: none;
                }
            }
            .right {
                width: 100%;
                .input-box {
                    width: 100%;
                    position: relative;
                    input {
                        width: 100%;
                        border: none;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 17px;
                        color: #303032;
                        padding: 0;
                        border-bottom: 1px solid #000000;
                        &::-webkit-input-placeholder {
                            font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 17px;
                        color: #303032;
                        }
                        &:-moz-placeholder {
                            font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 17px;
                        color: #303032;
                        }
                        &::-moz-placeholder {
                            font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 17px;
                        color: #303032;
                        }
                        &:-ms-input-placeholder {
                            font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 17px;
                        color: #303032;
                           }
                        
                        &:focus {
                            outline: none;
                            &+label {
                                opacity: 1;
                                transition: $transition;
                            }
                        }
                        &:not(:placeholder-shown) {
                            &+label {
                                opacity: 1;
                                transition: $transition;
                            }
                        }
                    }
                    label {
                        // display: none;
                        position: absolute;
                        top: -10px;
                        left: 0;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 8px;
                        line-height: 10px;
                        color: #565656;
                        opacity: 0;
                        transition: $transition;
                    }
                }
            }
        }
        .chat-content {
            max-height: 200px;
            min-height: 200px;
            width: 100%;
            overflow-y: auto;
            position: relative;
            &::-webkit-scrollbar {
                width: 5px;
            }
             
            &::-webkit-scrollbar-track {
                background: #fff;
            }
             
            &::-webkit-scrollbar-thumb {
                background: #C5C5C5;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }


            .chat-message-items {

                .action-box {
                    display: none;
                } 

                .chat-message-item {
                    display: flex;
                    align-items: center;
                    height: 55px;
                    padding: 10px 20px 10px 10px;
                    position: relative;
                    border-bottom: 1px solid #C5C5C5;
                    user-select: none;
                    position: relative;
                    cursor: pointer;

                    &.active-chek {
                        background-color: #f2f2f2;
                    }
                    .chat-message-item-icon {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 15px;
                        .indicator-online {
                            width: 7px;
                            display: none;
                            height: 7px;
                            background-color: #00CA14;
                            border: 1px solid #fff;
                            border-radius: 100%;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            &.active {
                                display: block;
                            }
                        }
                    }

                    .chat-message-item-info {
                        display: flex;
                        flex-direction: column;
                        p {
                            margin: 0;
                            font-size: 12px;
                            font-family: 'Montserrat';
                            line-height: 15px;
                            color: #222222;
                            font-style: normal;
                            &.chat-message-item-name {
                                font-weight: 500;
                                letter-spacing: 0.02em;
                                color: #222222;
                                margin-bottom: 5px;
                            }
                            // &.chat-message-item-desc {
                            // }
                        }
                    }
                    
                    .checkbox-wrapper {
                        
                        input {
                            display: none;
                            &:checked {
                                ~ .checkbox-icon {
                                    background-color:#303032;
                                    transition: $transition;
                                }
                            }
                        }
                        label {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            cursor: pointer;
                        }

                        .checkbox-icon {
                            width: 10px;
                            min-width: 10px;
                            border: 1px solid #303032;
                            border-radius: 100%;
                            height: 10px;
                            transition: $transition;
                            position: absolute;
                            right: 10px;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }
        }
        .chat-bottom {
            display: flex;
            min-height: 40px;
            height: 40px;
            .btn {
                width: 50%;
                height: 40px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.02em;
                color: #545456;
                border: none;
                &.btn-white {
                    background-color: #fff;
                }
                &.btn-yellow {
                    background-color: #FFCD00;
                }
            }
        }
    }

    .black-list-box {
        display: none;
        flex-direction: column;
        width: 270px;
        height: 360px;
        position: fixed;
        bottom: 10px;
        right: 80px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px 4px 0px 0px;

        .chat-top {
            background-color: #FFCD00;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            min-height: 40px;
            scroll-margin-bottom: 40px;
            padding: 0 10px;
            &-title {
                margin: 0;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #222222;
                display: flex;
                align-items: center;
                svg {
                    margin-left: 5px;
                }
            }

            .left {
                display: flex;
                align-items: center;
            }
            .prev {
                margin-right: 10px;
                &.hide {
                    display: none;
                }
            }

            .btn-items {
                display: flex;
                &>* {
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            
            .action-items {
                width: 160px;
                .action-item {
                    justify-content: space-between;
                }
            }
            .chat-settings {
                padding: 0 5px;
            }
            .chat-close {
                padding: 0 5px;
                
            }
        }

        .group-info {
            height: 80px;
            min-height: 80px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            .left {
                margin-left: 20px;
                margin-right: 20px;
                width: 50px;
                min-width: 50px;
                max-width: 50px;
                height: 50px;
                position: relative;

                input {
                    display: none;
                }
                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: #303032;
                }
                img {
                    position: absolute;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    top: 0;
                    left: 0;
                    display: none;
                }
            }
            .right {
                width: 100%;
                .input-box {
                    width: 100%;
                    input {
                        width: 100%;
                        border: none;
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 17px;
                        color: #303032;
                        border-bottom: 1px solid #000000;
                        &:focus {
                            outline: none;
                        }
                    }
                    label {
                        display: none;
                    }
                }
            }
        }
        .chat-content {
            max-height: 320px;
            height: 320px;
            width: 100%;
            overflow-y: auto;
            position: relative;
            &::-webkit-scrollbar {
                width: 5px;
            }
             
            &::-webkit-scrollbar-track {
                background: #fff;
            }
             
            &::-webkit-scrollbar-thumb {
                background: #C5C5C5;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }


            .chat-message-items {

                .action-box {
                    display: none;
                } 

                .chat-message-item {
                    display: flex;
                    align-items: center;
                    height: 55px;
                    padding: 10px 20px 10px 10px;
                    position: relative;
                    border-bottom: 1px solid #C5C5C5;
                    user-select: none;
                    position: relative;
                    cursor: pointer;

                    &.active-chek {
                        background-color: #f2f2f2;
                    }
                    .chat-message-item-icon {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 15px;
                        .indicator-online {
                            width: 7px;
                            display: none;
                            height: 7px;
                            background-color: #00CA14;
                            border: 1px solid #fff;
                            border-radius: 100%;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            &.active {
                                display: block;
                            }
                        }
                    }

                    .chat-message-item-info {
                        display: flex;
                        flex-direction: column;
                        p {
                            margin: 0;
                            font-size: 12px;
                            font-family: 'Montserrat';
                            line-height: 15px;
                            color: #222222;
                            font-style: normal;
                            &.chat-message-item-name {
                                font-weight: 500;
                                letter-spacing: 0.02em;
                                color: #222222;
                                margin-bottom: 5px;
                            }
                            // &.chat-message-item-desc {
                            // }
                        }
                    }
                    
                    .checkbox-wrapper {
                        
                        input {
                            display: none;
                            &:checked {
                                ~ .checkbox-icon {
                                    background-color:#303032;
                                    transition: $transition;
                                }
                            }
                        }
                        label {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            cursor: pointer;
                        }

                        .checkbox-icon {
                            width: 10px;
                            min-width: 10px;
                            border: 1px solid #303032;
                            border-radius: 100%;
                            height: 10px;
                            transition: $transition;
                            position: absolute;
                            right: 10px;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }
        }
        .chat-bottom {
            display: flex;
            min-height: 40px;
            height: 40px;
            .btn {
                width: 50%;
                height: 40px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.02em;
                color: #545456;
                border: none;
                &.btn-white {
                    background-color: #fff;
                }
                &.btn-yellow {
                    background-color: #FFCD00;
                }
            }
        }
    }

    .action-box {
        position: relative;
        .dots {
            display: flex;
            margin-left: 10px;
            cursor: pointer;
            span {
                display: block;
                width: 4px;
                height: 4px;
                background-color: #c5c5c5;
                border-radius: 50%;
                margin-right: 2px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .chat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 10px;
        right: 10px;
        background: #222222;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transition: $transition;
        cursor: pointer;
        &:hover {
            transition: $transition;
            opacity: .7;
        }
    }
}

.chat-settings {
    position: relative;
    .action-items {
        right: 5px;
        top: 20px;
    }
}

.chat-group {
    .action-items {
        right: 5px;
        top: 20px;
    }
}

.action-items {
    position: absolute;
    display: none;
    width: 120px;
    padding: 10px;
    right: 0px;
    top: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 10;
    &.active{
        display: block;
    }
    .action-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        user-select: none;
        &:last-child {
            margin-bottom: 0;
        }
        p {
            margin: 0;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
            color: #222222;
        }
        svg {
            margin-left: 5px;
        }
    }
}

.chat-wrapper .black-list-box .chat-content.show-dots .chat-message-items .action-box,
.chat-wrapper .chat-box .chat-content.show-dots .chat-message-items .action-box,
.chat-wrapper .chat-group .chat-content.show-dots .chat-message-items .action-box,
.chat-wrapper .chat-write-message .chat-content.show-dots .chat-message-items .action-box {
    display: block;
}
